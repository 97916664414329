<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="$t('padrao_deseja_remover_registro')"
    :mostra-botao-salvar-continuar="false"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
    @save-continue-event="saveContinueEvent"
  >
    <v-container class="py-0">
      <v-row>
        <v-col
          cols="12"
        >
          <empresa-cliente-auto-complete v-model="empresaClienteModel" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="objEditar.nome"
            :label="$t('UserForm.nome')"
            class="purple-input"
            prepend-icon="mdi-account-edit"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.email"
            :label="$t('UserForm.email')"
            class="purple-input"
            prepend-icon="mdi-currency-usd"
            type="email"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.password"
            :label="$t('UserForm.password')"
            class="purple-input"
            prepend-icon="mdi-coin-outline"
            type="password"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-switch
            v-model="objEditar.habilitado"
            :label="$t('UserForm.habilitado')"
            class="purple-input"
            prepend-icon="mdi-notebook-multiple"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-switch
            v-model="objEditar.changePasswordNextLogin"
            :label="$t('UserForm.changePasswordNextLogin')"
            class="purple-input"
            prepend-icon="mdi-notebook-multiple"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="objEditar.permissao"
            :label="$t('UserForm.permissao')"
            item-value="value"
            item-text="text"
            :items="permissoes"
            prepend-icon="mdi-calendar-alert"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-menu
            ref="menuDataNascimento"
            v-model="menuDataNascimento"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataNascimentoFormatted"
                :label="$t('ClientesForm.dataNascimento')"
                persistent-hint
                readonly
                prepend-icon="mdi-calendar-text"
                @blur="objEditar.dataNascimento = parseDate(dataNascimentoFormatted)"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="objEditar.dataNascimento"
              no-title
              dark
              @input="menuDataNascimento = false"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </crud-form>
</template>

<script>
  import Servico from '@/services/UserService'
  import EmpresaClienteAutoComplete from '@/views/EmpresaClienteAutoComplete'
  import DateUtils from '../utils/DateUtils'

  export default {
    components: {
      EmpresaClienteAutoComplete
    },
    data: () => ({
      empresaClienteModel: null,
      menuDataNascimento: false,
      objPadrao: {
        nome: '',
        email: '',
        habilitado: true,
        dataNascimento: null,
        changePasswordNextLogin: true,
        idEmpresaCliente: null,
        password: '',
        permissao: 'S'
      },
      objEditar: {
        nome: '',
        email: '',
        habilitado: true,
        dataNascimento: null,
        changePasswordNextLogin: true,
        idEmpresaCliente: null,
        password: '',
        permissao: 'S'
      },
      isEditting: true,
      permissoes: []
    }),
    computed: {
      tituloPagina () {
        return this.$i18n.t('UserForm.tituloPagina')
      },
      dataNascimentoFormatted: function () {
        return DateUtils.formatDate(this.objEditar.dataNascimento)
      },
    },
    watch: {
      empresaClienteModel (val) {
        if (val === null || !val) {
          this.objEditar.EmpresaCliente = null
          this.objEditar.idEmpresaCliente = null
        } else {
          this.objEditar.EmpresaCliente = val
          this.objEditar.idEmpresaCliente = val.id
        }
      }
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)

      this.fillDefaultPermissions()
      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = this.objPadrao
      }
    },
    methods: {
      parseDate (date) {
        return DateUtils.parseDate(date)
      },
      fillDefaultPermissions () {
        this.permissoes = []
        this.permissoes.push({
          text: this.$t('UserForm.permissaoAdministrador'),
          value: 'A'
        })
        this.permissoes.push({
          text: this.$t('UserForm.permissaoAdministradorCliente'),
          value: 'C'
        })
        this.permissoes.push({
          text: this.$t('UserForm.permissaoUsuarioSimples'),
          value: 'S'
        })
      },

      saveContinueEvent () {
        this.inserir(this.objEditar, true)
      },
      saveEventHandler () {
        if (this.isEditting) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
      },
      atualizar (item) {
        try {
          Servico.update(item).then((response) => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
              })
              this.closeDialog()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao:
              'Não foi possível concluir a atualização, tente mais tarde\n' +
              error
          })
        }
      },
      closeDialog () {
        this.$router.push('/users')
      },
      remover () {
        const item = this.objEditar
        try {
          Servico.delete(item).then((response) => {
            this.$root.$emit('mostra_dialogo_info', {
              dialogoTitulo: this.$t('padrao_sucesso'),
              dialogoDescricao: 'Item removido com sucesso'
            })
            this.closeDialog()
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao remover um Item:\n ->' + error
          })
        }
      },
      inserir (item, manterNestaTela) {
        try {
          Servico.insert(item)
            .then((response) => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao:
                    'Deu algum erro,\nResposta do Servidor: null '
                })
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
              } else {
                this.$root.$emit('mostra_dialogo_info', {
                  dialogoTitulo: this.$t('padrao_sucesso'),
                  dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
                })
                if (manterNestaTela) {
                  this.editar(item.data.id)
                } else {
                  this.closeDialog()
                }
              }
            })
            .catch((error) => {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao:
                  'Ocorreu um erro ao inserir um Item:\n ->' + error
              })
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir um Item:\n ->' + error
          })
        }
      },
      editar (id) {
        Servico.findById({ id })
          .then((item) => {
            this.isEditting = true
            this.objEditar = item.data
            this.empresaClienteModel = this.objEditar.EmpresaCliente
          })
          .catch(() => {
            this.isEditting = false
          })
      }
    }
  }
</script>
